<template>
  <div>
    <div class="slider">
      abc
      <vue-slider
        v-model="lengthModel"
        v-bind:min="options.length.min"
        v-bind:max="options.length.max"
        v-bind:disabled="options.length.disabled"
        v-show="!options.length.hidden"
        :marks="sparseMarks"
        :tooltip="'none'"
        :hide-label="true"
      >
        <template v-slot:dot="{ value }">
          <div class="custom-dot vue-slider-dot-handle">{{ value }}</div>
        </template>
      </vue-slider>
    </div>
    <div class="slider">
      123
      <vue-slider
        v-model="numbersModel"
        :vData="data"
        :tooltip="'none'"
        v-bind:disabled="options.numbers.disabled"
        v-show="!options.numbers.hidden"
      >
        <template v-slot:dot>
          <div class="custom-dot vue-slider-dot-handle">&nbsp;</div>
        </template></vue-slider
      >
    </div>
    <div class="slider">
      !@£
      <vue-slider
        v-model="symbolsModel"
        :vData="data"
        :tooltip="'none'"
        v-bind:disabled="options.symbols.disabled"
        v-show="!options.symbols.hidden"
      >
        <template v-slot:dot>
          <div class="custom-dot vue-slider-dot-handle">&nbsp;</div>
        </template></vue-slider
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import VueSlider from "vue-slider-component";
import { RandomOptions } from "@/modules/company/interface";
import { useModelWrapper } from "@/modules/utils";

export default defineComponent({
  name: "RandomSliders",
  components: {
    VueSlider,
  },
  props: {
    options: Object as PropType<RandomOptions>,
    length: Number,
    symbols: String,
    numbers: String,
  },
  data: function () {
    return {
      data: ["false", "true"],
      sparseMarks: (val: number) => val % 5 === 0,
    };
  },
  setup(props, { emit }) {
    return {
      lengthModel: useModelWrapper(props, emit, "length"),
      numbersModel: useModelWrapper(props, emit, "numbers"),
      symbolsModel: useModelWrapper(props, emit, "symbols"),
    };
  },
});
</script>
