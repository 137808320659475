const API_URL = process.env.VUE_APP_API_URL;
import { Company, Words } from "./interface";

function api<T>(path: string, locale = "en-GB"): Promise<T> {
  return fetch(`${API_URL}${path}`, { headers: { "Accept-Language": locale } })
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          response.status === 404 ? "Not Found" : "Unknown Error"
        );
      }
      return response.json() as Promise<T>;
    })
    .then((data) => data);
}

const getCompany = async (
  companyId: string
): Promise<{ error: string } | Company> => {
  try {
    return api<Company>(`policies/${companyId}`);
  } catch (e) {
    return { error: e.toString() };
  }
};

const getWords = async (companyId: string, locale: string): Promise<Words> =>
  api<Words>(`policies/${companyId}/words`, locale);

export { getCompany, getWords };
