
import { defineComponent, PropType } from "vue";
import VueSlider from "vue-slider-component";
import { StructuredOptions } from "@/modules/company/interface";
import { useModelWrapper } from "@/modules/utils";

export default defineComponent({
  name: "StructuredSliders",
  components: {
    VueSlider,
  },
  props: {
    options: Object as PropType<StructuredOptions>,
    words: Number,
    symbols: Number,
    numbers: Number,
  },
  setup(props, { emit }) {
    return {
      wordsModel: useModelWrapper(props, emit, "words"),
      numbersModel: useModelWrapper(props, emit, "numbers"),
      symbolsModel: useModelWrapper(props, emit, "symbols"),
    };
  },
});
