
import { defineComponent, PropType } from "vue";
import VueSlider from "vue-slider-component";
import { RandomOptions } from "@/modules/company/interface";
import { useModelWrapper } from "@/modules/utils";

export default defineComponent({
  name: "RandomSliders",
  components: {
    VueSlider,
  },
  props: {
    options: Object as PropType<RandomOptions>,
    length: Number,
    symbols: String,
    numbers: String,
  },
  data: function () {
    return {
      data: ["false", "true"],
      sparseMarks: (val: number) => val % 5 === 0,
    };
  },
  setup(props, { emit }) {
    return {
      lengthModel: useModelWrapper(props, emit, "length"),
      numbersModel: useModelWrapper(props, emit, "numbers"),
      symbolsModel: useModelWrapper(props, emit, "symbols"),
    };
  },
});
