<template>
  <nav class="navigation">
    <img src="../assets/logo.svg" alt="Rune Logo" class="logo" />
    <h2>
      <i class="fas fa-check"></i><span>{{ $t("nav") }}</span>
    </h2>

    <img src="../assets/N.svg" alt="Rune Logo" class="small-logo" />
    <div class="sliders">
      <RandomSliders
        v-if="company?.policy?.random"
        :options="company?.policy?.random"
        v-model:length="config.length"
        v-model:numbers="config.numbers"
        v-model:symbols="config.symbols"
      ></RandomSliders>

      <StructuredSliders
        v-if="company?.policy?.structured"
        :options="company?.policy?.structured"
        v-model:words="config.words"
        v-model:numbers="config.numbers"
        v-model:symbols="config.symbols"
      ></StructuredSliders>
    </div>
  </nav>

  <div class="generate">
    <section>
      <div class="logos">
        <img
          height="100"
          v-bind:src="company.logo"
          v-bind:alt="`${company.company_id} Logo`"
        />
      </div>
      <div>{{ $t("placeholder.simpleGeneration") }}</div>
      <div>
        <div v-if="showDisclaimer" class="disclaimer">
          <div class="close" @click.stop="showDisclaimer = false">
            <img src="../assets/close.svg" alt="Close" />
          </div>
          <img src="../assets/warn.svg" alt="Warning" />
          <h2>{{ $t("disclaimer.remember") }}</h2>
          {{ $t("disclaimer.first") }}
          <h2>{{ $t("disclaimer.important") }}</h2>
          {{ $t("disclaimer.second") }}
        </div>
        <div class="copied">{{ copied ? $t("copied") : "" }}</div>
        <form action="">
          <div
            class="button generate-button"
            id="password"
            v-on:click="clickHandler"
            v-bind:class="[
              password ? 'password-button' : '',
              bigPassword ? 'big-password' : '',
              showDisclaimer ? '' : 'show-copy',
            ]"
          >
            <div>{{ generateButtonCaption }}</div>
            <div
              v-if="password"
              class="view-password"
              @click.stop="showPassword = !showPassword"
            >
              <img src="../assets/eye.svg" alt="Show" />
            </div>
          </div>
          <a
            v-bind:style="{
              visibility: password ? 'visible' : 'hidden',
            }"
            @click.prevent="generate"
            class="regenerate"
          >
            {{ $t("button.regenerate") }}
          </a>
        </form>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RandomSliders from "@/components/RandomSliders.vue";
import StructuredSliders from "@/components/StructuredSliders.vue";
import { getCompany, getWords, Company } from "@/modules/company/";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import "vue-slider-component/theme/material.css";

const getRandomValueFromArrayOrString = (
  dictionary: string | string[],
  count: number
): string[] => {
  return Array.from(crypto.getRandomValues(new Uint32Array(count))).map(
    (x) => dictionary[x % dictionary.length]
  );
};

export default defineComponent({
  name: "Generate",
  components: {
    RandomSliders,
    StructuredSliders,
  },
  props: {
    companyId: String,
  },
  setup() {
    return { toClipboard };
  },
  data() {
    return {
      password: "",
      copied: false,
      error: "",
      bigPassword: false,
      company: {} as Company,
      config: {
        length: 0,
        words: 1,
        numbers: "false" as string | number,
        symbols: "false" as string | number,
      },
      showPassword: false,
      showDisclaimer: false,
    };
  },
  computed: {
    generateButtonCaption(): string {
      if (!this.password) {
        return this.$t("button.generate");
      }
      if (!this.showPassword) {
        return "*".repeat(this.password.length);
      }

      return this.password;
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const company = (await getCompany(
        to.params.companyId.toString()
      )) as Company;
      next((vm: any) => vm.setCompany(company));
    } catch (error) {
      if (from.name) {
        return next(new Error("not found"));
      }
      return next({ name: "Home", query: { error: error.message } });
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      const company = (await getCompany(
        to.params.companyId.toString()
      )) as Company;
      this.setCompany(company);

      next();
    } catch (error) {
      return next({ name: "Home", query: { error: error.message } });
    }
  },

  methods: {
    setCompany(company: Company) {
      this.company = company;
      const { random, structured } = company.policy;

      if (random) {
        this.config.length = random.length.default;
        this.config.symbols = random.symbols.default.toString();
        this.config.numbers = random.numbers.default.toString();
      }

      if (structured) {
        this.config.words = structured.words.default;
        this.config.symbols = structured.symbols.default;
        this.config.numbers = structured.numbers.default;
      }
    },
    clickHandler() {
      if (this.password) {
        this.toClipboard(this.password);
        this.copied = true;
        setTimeout(() => (this.copied = false), 3000);
      } else {
        this.showDisclaimer = true;
        this.generate();
      }
    },

    async generate() {
      try {
        if (this.company.policy.random) {
          let characterList =
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

          if (this.config.numbers === "true") {
            characterList += "0123456789";
          }
          if (this.config.symbols === "true") {
            characterList += "!@£$%^&*()±§<>:;|/?\\[]{}";
          }
          //
          this.password = getRandomValueFromArrayOrString(
            characterList,
            this.config.length
          ).join("");

          this.bigPassword = this.password.length > 15;

          return;
        }

        if (this.company.policy.structured) {
          // Get some words:
          const { dictionary } = await getWords(
            this.company.company_id,
            this.$i18n.locale
          );

          const wordCount = this.config.words;
          const symbolsCount = this.config.symbols;
          const numbersCount = this.config.numbers;

          const symbols = Array(wordCount);
          const numbers = Array(wordCount);

          let words = getRandomValueFromArrayOrString(dictionary, wordCount);

          words = Array.from(new Set(words));

          while (words.length !== wordCount) {
            words.push(getRandomValueFromArrayOrString(dictionary, 2)[0]);
            words = Array.from(new Set(words));
          }

          // Pick x word boundaries. Add Symbols
          for (let i = 0; i < symbolsCount; i++) {
            const index = Math.floor(Math.random() * wordCount);
            const symbol = getRandomValueFromArrayOrString(
              "!@£$%^&*()<>:;|/?\\",
              1
            ).join("");

            symbols[index] = symbols[index]
              ? `${symbols[index]}${symbol}`
              : symbol;
          }

          // Pick x word boundaries. Add Numbers
          for (let i = 0; i < numbersCount; i++) {
            const index = Math.floor(Math.random() * wordCount);
            const number = getRandomValueFromArrayOrString(
              "0123456789",
              1
            ).join("");

            numbers[index] = numbers[index]
              ? `${numbers[index]}${number}`
              : number;
          }

          //Titlecase some words
          for (let i = 0; i < Math.floor(wordCount / 2); i++) {
            const index = Math.floor(Math.random() * wordCount);
            const word = words[index];

            words[index] = `${word[0].toUpperCase()}${word.substr(1)}`;
          }

          // Join it all up
          const result = [];
          for (let i = 0; i < wordCount; i++) {
            result.push(words[i]);
            result.push(symbols[i]);
            result.push(numbers[i]);
          }

          this.password = result.join("");

          if (this.password.length > 15) {
            this.bigPassword = true;
          } else {
            this.bigPassword = false;
          }
        }
      } catch (error) {
        // Something went wrong, most likely getting a dict
        console.error(error);
        this.$router.push({ name: "Home", query: { error: "Unknown Error" } });
      }
    },
  },
});
</script>

<style lang="scss">
.logos img {
  max-width: 100%;
}

.disclaimer {
  position: absolute;
  box-sizing: border-box;
  background: rgba(200, 200, 200, 0.4);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 15px;
  padding: 20px 40px 40px;
  z-index: 100;
  width: 100%;
  max-width: 600px;
  text-align: left;
  font-size: 22px;
  line-height: 27px;
  font-weight: normal;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  h2 {
    font-size: inherit;
    color: #ff0062;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 0;
    line-height: inherit;
  }
}

.generate {
  font-weight: bold;
  width: 100%;
  height: 100%;
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  box-sizing: border-box;

  text-align: center;
  > section {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -50px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    > div {
      padding-bottom: 50px;
      box-sizing: border-box;
      max-width: 100%;
    }
  }
}

.generate-button > div {
  overflow-x: auto;
  width: 92%;
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
  &.view-password {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    user-select: none;
  }
}

.regenerate {
  color: #009dff;
  text-decoration: underline;
  font-weight: normal;
  display: block;
  cursor: pointer;
  margin-top: 20px;
}

.copied {
  height: 30px;
  line-height: 30px;
  padding-bottom: 10px;
  font-style: italic;
  color: #009dff;
  font-weight: normal;
}

div.button {
  cursor: pointer;
  background: transparent linear-gradient(90deg, #a700ff 0%, #009dff 100%) 0 0
    no-repeat padding-box;
  max-width: 100%;
  &.password-button {
    background: rgba(241, 241, 241, 0.85);
    border-radius: 5px;
    color: #000;
    position: relative;
    &.big-password {
      width: 650px;
      font-size: 26px;
    }

    &.show-copy::after {
      content: "";
      background: url("../assets/copy.svg");
      width: 70px;
      height: 76px;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: -74px;
      background-position: center;
      color: #009dff;
    }
  }
}

fieldset {
  border: 0;
}

label {
  display: block;
  text-align: center;
  width: 35px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 12px;
  padding-left: 24px;
  line-height: 30px;
  cursor: pointer;
  &.disabled {
    color: #a1a2a5;
  }
}
.small-logo {
  width: 25px;
  position: absolute;
  bottom: 250px;
}

.sliders {
  position: absolute;
  right: 0;
  left: 50%;
  margin-left: -240px;
  bottom: 45px;
}

.slider {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 11px;
  font-size: 11px;
  font-weight: normal;
  margin-bottom: 20px;
  cursor: pointer;
}

div.vue-slider {
  height: 1px !important;
  width: 220px !important;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  float: right;
  top: 12px;
  &:focus {
    outline: none !important;
  }
}

.vue-slider:hover .vue-slider-rail {
  background-color: #000;
}

.vue-slider-dot {
  margin-left: 7px;
  margin-top: -3px;
}

.vue-slider-dot-handle {
  margin-top: -2px;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  display: block;
  line-height: 22px;
  &:after {
    background-color: transparent;
  }
}

.vue-slider-dot-handle,
.vue-slider-rail::after {
  background: #000;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 0;
}

.vue-slider-process {
  background: #000;
}

.vue-slider-rail {
  background: #000;
  outline: none;
  &::before {
    content: " ";
    border-right: 1px solid #000;
    height: 22px;
    float: left;
    margin-top: -11px;
  }
  &::after {
    display: block;
    background: transparent;
    border: 1px solid #000;
    content: " ";
    float: right;
    margin-top: -12px;
    margin-right: -22px;
    width: 20px;
    height: 20px;
  }
}

.vue-slider-marks {
  > div:first-child {
    display: none;
  }

  > div:last-child {
    display: none;
  }
}
.vue-slider-mark-step-active {
  box-shadow: none;
}

.vue-slider-mark-step {
  height: 5px;
  box-shadow: none;
  background: black;
  border-radius: 0;
  width: 1px;
  margin-left: 15px;
}

nav {
  background: rgba(255, 255, 255, 0.3);
  height: 100%;
  margin-top: 0;
  order: 0;
  flex: 0 1 420px;
  align-self: auto;
  min-width: 420px;
  opacity: 1;
  backdrop-filter: blur(38px);
  -webkit-backdrop-filter: blur(38px);
  position: relative;
  .logo {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    margin-top: 30px;
    margin-bottom: 80px;
  }

  h2 {
    text-align: left;
    margin-left: 90px;
    display: inline-block;
    font-size: 26px;
    line-height: 36px;
    font-weight: bold;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    text-decoration: none;
    margin-top: 0;
    margin-bottom: 100px;
    position: relative;
    width: 100%;
    span {
      border-bottom: 1px solid #000;
    }

    i.fas {
      left: -50px;
      position: absolute;
      background: #009dff;
      border-radius: 24px;
      font-size: 17px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      color: #fff;
    }

    &.disabled {
      border: 0;
      text-decoration: none;
      font-weight: 400;
      span {
        border-bottom: none;
      }
      i {
        background-color: #a1a2a5;
      }
      div {
        font-size: 20px;
        line-height: 27px;
        font-style: italic;
        color: #a1a2a5;
        text-align: center;
        width: 250px;
      }
    }

    &:visited,
    &:hover {
      color: #000000;
    }
  }

  .disabled {
    color: #888;
  }
}

@media only screen and (max-width: 1000px) {
  nav {
    flex: 1 1 100%;
    width: 100%;
    order: 1;
    background: transparent;
    backdrop-filter: none;
    min-width: auto;
    > h2 {
      display: none;
    }
  }

  .small-logo {
    position: initial;
  }

  .logo {
    display: none;
  }

  .logos img {
    height: auto;
    max-height: 100px;
    margin-bottom: 20px;
  }

  .generate {
    width: 100%;
    flex: 1 0 auto;
    height: auto;
    padding-bottom: 30px;
    > section {
      margin-top: 30px;

      > div {
        padding: 0;
        width: 100%;
        position: relative;
      }
      .disclaimer {
        height: 375px;
        width: 100%;
        max-width: 100%;
        top: 30px;
        left: 0;
      }
    }
  }

  .sliders {
    position: initial;
    margin-left: -46px;
    margin-top: 20px;
  }

  div.button {
    font-size: 18px;
    height: 64px;
    line-height: 64px;
    &.password-button {
      max-width: 100%;
      &.big-password {
        font-size: 14px;
      }
      &::after {
        display: none;
      }
    }
  }
  .copied,
  .regenerate {
    font-size: 22px;
  }
  .controls {
    margin-left: -20px;
  }
}

@supports (-moz-appearance: none) {
  // FF
  nav {
    background: rgba(255, 255, 255, 0.9);
  }

  .disclaimer {
    background: rgba(210, 210, 210, 0.95);
  }

  .small-logo {
    left: 212px;
  }
}
</style>
