<template>
  <div>
    <div class="slider">
      <span>
        {{ $t("slider.words.label") }}
        <div class="hover">{{ $t("slider.words.tooltip") }}</div>
      </span>
      <vue-slider
        v-model="wordsModel"
        v-bind:min="options.words.min"
        v-bind:max="options.words.max"
        v-bind:disabled="options.words.disabled"
        v-show="!options.words.hidden"
        :marks="true"
        :tooltip="'none'"
        :hide-label="true"
      >
        <template v-slot:dot="{ value }">
          <div class="custom-dot vue-slider-dot-handle">{{ value }}</div>
        </template></vue-slider
      >
    </div>
    <div class="slider">
      <span>
        {{ $t("slider.numbers.label") }}
        <div class="hover">{{ $t("slider.numbers.tooltip") }}</div>
      </span>
      <vue-slider
        v-model="numbersModel"
        :tooltip="'none'"
        v-bind:min="options.numbers.min"
        v-bind:max="options.numbers.max"
        v-bind:disabled="options.numbers.disabled"
        v-show="!options.numbers.hidden"
        :marks="true"
        :hide-label="true"
      >
        <template v-slot:dot="{ value }">
          <div class="custom-dot vue-slider-dot-handle">{{ value }}</div>
        </template>
      </vue-slider>
    </div>
    <div class="slider">
      <span>
        {{ $t("slider.symbols.label") }}
        <div class="hover">{{ $t("slider.symbols.tooltip") }}</div>
      </span>
      <vue-slider
        v-model="symbolsModel"
        :tooltip="'none'"
        v-bind:min="options.symbols.min"
        v-bind:max="options.symbols.max"
        v-bind:disabled="options.symbols.disabled"
        v-show="!options.symbols.hidden"
        :marks="true"
        :hide-label="true"
      >
        <template v-slot:dot="{ value }">
          <div class="custom-dot vue-slider-dot-handle">{{ value }}</div>
        </template></vue-slider
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import VueSlider from "vue-slider-component";
import { StructuredOptions } from "@/modules/company/interface";
import { useModelWrapper } from "@/modules/utils";

export default defineComponent({
  name: "StructuredSliders",
  components: {
    VueSlider,
  },
  props: {
    options: Object as PropType<StructuredOptions>,
    words: Number,
    symbols: Number,
    numbers: Number,
  },
  setup(props, { emit }) {
    return {
      wordsModel: useModelWrapper(props, emit, "words"),
      numbersModel: useModelWrapper(props, emit, "numbers"),
      symbolsModel: useModelWrapper(props, emit, "symbols"),
    };
  },
});
</script>

<style scoped lang="scss">
.slider {
  height: 39px;
  position: relative;
}
span {
  width: 70px;
  height: 100%;
  display: block;
  line-height: 10px;
  position: absolute;
  left: -45px;
  line-height: 39px;
  text-align: right;
  .hover {
    position: absolute;
    white-space: nowrap;
    left: 74px;
    text-align: left;
    top: 6px;
    padding: 7px;
    background-color: white;
    border: 1px black solid;
    border-radius: 4px;
    z-index: 10;
    line-height: 10px;
    visibility: hidden;
  }
  &:hover .hover {
    visibility: visible;
  }
}
</style>
