import { computed } from "vue";

export function useModelWrapper(
  props: any,
  emit: (arg0: string, arg1: any) => void,
  name = "modelValue"
) {
  return computed({
    get: () => props[name],
    set: (value) => emit(`update:${name}`, value),
  });
}
