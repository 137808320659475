
import { defineComponent } from "vue";
import RandomSliders from "@/components/RandomSliders.vue";
import StructuredSliders from "@/components/StructuredSliders.vue";
import { getCompany, getWords, Company } from "@/modules/company/";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import "vue-slider-component/theme/material.css";

const getRandomValueFromArrayOrString = (
  dictionary: string | string[],
  count: number
): string[] => {
  return Array.from(crypto.getRandomValues(new Uint32Array(count))).map(
    (x) => dictionary[x % dictionary.length]
  );
};

export default defineComponent({
  name: "Generate",
  components: {
    RandomSliders,
    StructuredSliders,
  },
  props: {
    companyId: String,
  },
  setup() {
    return { toClipboard };
  },
  data() {
    return {
      password: "",
      copied: false,
      error: "",
      bigPassword: false,
      company: {} as Company,
      config: {
        length: 0,
        words: 1,
        numbers: "false" as string | number,
        symbols: "false" as string | number,
      },
      showPassword: false,
      showDisclaimer: false,
    };
  },
  computed: {
    generateButtonCaption(): string {
      if (!this.password) {
        return this.$t("button.generate");
      }
      if (!this.showPassword) {
        return "*".repeat(this.password.length);
      }

      return this.password;
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const company = (await getCompany(
        to.params.companyId.toString()
      )) as Company;
      next((vm: any) => vm.setCompany(company));
    } catch (error) {
      if (from.name) {
        return next(new Error("not found"));
      }
      return next({ name: "Home", query: { error: error.message } });
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      const company = (await getCompany(
        to.params.companyId.toString()
      )) as Company;
      this.setCompany(company);

      next();
    } catch (error) {
      return next({ name: "Home", query: { error: error.message } });
    }
  },

  methods: {
    setCompany(company: Company) {
      this.company = company;
      const { random, structured } = company.policy;

      if (random) {
        this.config.length = random.length.default;
        this.config.symbols = random.symbols.default.toString();
        this.config.numbers = random.numbers.default.toString();
      }

      if (structured) {
        this.config.words = structured.words.default;
        this.config.symbols = structured.symbols.default;
        this.config.numbers = structured.numbers.default;
      }
    },
    clickHandler() {
      if (this.password) {
        this.toClipboard(this.password);
        this.copied = true;
        setTimeout(() => (this.copied = false), 3000);
      } else {
        this.showDisclaimer = true;
        this.generate();
      }
    },

    async generate() {
      try {
        if (this.company.policy.random) {
          let characterList =
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

          if (this.config.numbers === "true") {
            characterList += "0123456789";
          }
          if (this.config.symbols === "true") {
            characterList += "!@£$%^&*()±§<>:;|/?\\[]{}";
          }
          //
          this.password = getRandomValueFromArrayOrString(
            characterList,
            this.config.length
          ).join("");

          this.bigPassword = this.password.length > 15;

          return;
        }

        if (this.company.policy.structured) {
          // Get some words:
          const { dictionary } = await getWords(
            this.company.company_id,
            this.$i18n.locale
          );

          const wordCount = this.config.words;
          const symbolsCount = this.config.symbols;
          const numbersCount = this.config.numbers;

          const symbols = Array(wordCount);
          const numbers = Array(wordCount);

          let words = getRandomValueFromArrayOrString(dictionary, wordCount);

          words = Array.from(new Set(words));

          while (words.length !== wordCount) {
            words.push(getRandomValueFromArrayOrString(dictionary, 2)[0]);
            words = Array.from(new Set(words));
          }

          // Pick x word boundaries. Add Symbols
          for (let i = 0; i < symbolsCount; i++) {
            const index = Math.floor(Math.random() * wordCount);
            const symbol = getRandomValueFromArrayOrString(
              "!@£$%^&*()<>:;|/?\\",
              1
            ).join("");

            symbols[index] = symbols[index]
              ? `${symbols[index]}${symbol}`
              : symbol;
          }

          // Pick x word boundaries. Add Numbers
          for (let i = 0; i < numbersCount; i++) {
            const index = Math.floor(Math.random() * wordCount);
            const number = getRandomValueFromArrayOrString(
              "0123456789",
              1
            ).join("");

            numbers[index] = numbers[index]
              ? `${numbers[index]}${number}`
              : number;
          }

          //Titlecase some words
          for (let i = 0; i < Math.floor(wordCount / 2); i++) {
            const index = Math.floor(Math.random() * wordCount);
            const word = words[index];

            words[index] = `${word[0].toUpperCase()}${word.substr(1)}`;
          }

          // Join it all up
          const result = [];
          for (let i = 0; i < wordCount; i++) {
            result.push(words[i]);
            result.push(symbols[i]);
            result.push(numbers[i]);
          }

          this.password = result.join("");

          if (this.password.length > 15) {
            this.bigPassword = true;
          } else {
            this.bigPassword = false;
          }
        }
      } catch (error) {
        // Something went wrong, most likely getting a dict
        console.error(error);
        this.$router.push({ name: "Home", query: { error: "Unknown Error" } });
      }
    },
  },
});
